<template>
  <!-- div class="h-100 d-flex align-items-center flex-column justify-content-center">
      <div class="page-header" -->
        <div><div>
        <div class="comnlogo">
          <img src="@/assets/COMN_Paths_Horizontal_Color_cropped_and_shrunk.png"/>
        </div>
        <!--nav -->
          <!-- router-link to="/">Home</router-link> |
          <router-link to="/surveys">Surveys</router-link --> 
          <span v-if="isLoggedIn()">
            <!-- router-link :to="{
                  name: 'AuthBounce',
                  params: { inout: logout }}"
                  >LogoutNew</router-link -->
            <a href="#" @click="logout()"> Logout </a> 
            <p class="instructions">
              Reload this page to see your most recent progress.
            </p>
          </span>
          <span v-else>
            <!-- router-link :to="{
                  name: 'AuthBounce',
                  params: { inout: login }}"
                  >LoginNew</router-link -->
            <a href="#" @click="login()"> Login </a>
          </span>
      <!-- /nav -->
      </div>
  </div>
  <router-view/>
</template>
<script>
 /* eslint-disable */
 import { useStore } from 'vuex'

import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

export default {
  name: 'App',
  data: () => ({
    loggedIn: false
  }),
  methods: {
    logout() {
      // const store = useStore()
      this.$store.dispatch("logout");
    },
    login() {
      // const store = useStore()
      this.$store.dispatch("login");
    },
    isLoggedIn(){
      console.log(this.loggedIn)
      return this.loggedIn
    }
  },
  mounted() {
    const store = useStore()
    Sentry.init({
      dsn: "https://896326a221944e0586d44e6ee68617d2@o59337.ingest.sentry.io/4505315007528960",
      integrations: [new BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.1,
    })
    Sentry.setUser({ username: "test  " });
    Sentry.setTag("parent-workflow", "comn")

    this.loggedIn = store.getters.loggedIn;
    var params = []
    var code = undefined
    var state = undefined
    let path = window.location.pathname
    
//console.log(`hash ${window.location.hash}`)
    const paramsi = new URLSearchParams(
        window.location.hash.substring(1) // any_hash_key=any_value
    );
    const has_token = paramsi.get('/id_token');

if(path === '/logout' || has_token === null){
// if(path === '/logout' || (path === '/' && window.location.hash === undefined)){
      console.log(path)
      this.loggedIn = false
      store.commit("unsetUser")
    }else{
      console.log("in");
      const url = new URL(document.location);
      // var paramsi = (url.hash);
      const paramsi = new URLSearchParams(
        window.location.hash.substring(1) // any_hash_key=any_value
      );
      const token = paramsi.get('/id_token');
      const code = 'test' // paramsi.get('code');
      const state = 'test' //paramsi.get('state');
      const expires_in = paramsi.get('expires_in');
      
      // console.log(`query: ${url}`) 
      // console.log(`query: ${token}`) 

/*
      params = window.location.search.substr(1).split("&")
      params.forEach(function (item) {
        let tmp = item.split("=");
        if (tmp[0] === "code") {
          code = tmp[1]
        }else if(tmp[0] === 'state') {
          console.log(`state ${tmp[1]}`)
          state = tmp[1]
        }else if(tmp[0] === 'id_token'){
          console.log(`id_token ${tmp[1]}`)
        }else if(tmp[0] === 'logout'){

        }
      });
  */
      // TODO: this needs proper validation
      if(code && state){
        let payload = [code, state, token, expires_in]
        store.commit("setUser", payload)
        this.loggedIn = true
        this.$router.push('/surveys')
      }
    }


   
  },
  components: {
  }
}

</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.header-old {
  margin-top: 10px;
  margin-bottom: 30px;
  margin-right: 150px;
  margin-left: 80px;
}

.header {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 50px;
  margin-left: 50px;
  
  padding: 60px;
  text-align: center;
  background: #1abc9c;
  color: white;
  font-size: 30px;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

img{
  margin-top: 10px;
  margin-bottom: 50px;
  max-width: 80%;
  max-height: 50%;     
}

a {font-size: 30px}

a:link, a:visited {
  color: (internal value);
  text-decoration: underline;
  cursor: auto;
}

a:link:active, a:visited:active {
  color: (internal value);
}
/* mouse over link */
a:hover {
  color: green;
}

/* selected link */
a:active {
  color: blue;
}
</style>
