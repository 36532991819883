 /* eslint-disable */
/**
 * Encapsulates the server interactions, and abstracts the API conventions
 */
// import axios from 'axios'
// const http = axios.create({ headers })
// import axios from 'axios'
// import * as Sentry from '@sentry/browser'

// const headers = {}
// const http = axios.create({ headers })
const taskModule = {
  actions: {
        oauth_login(){
      window.location.replace('https://login.research.latis.umn.edu/login?response_type=token&client_id=2f8e8pntgoiq5dn0gdihepm5m3&redirect_uri=https://comn.psych.umn.edu/&state=STATE&scope=openid+email+aws.cognito.signin.user.admin');
    },
    oauth_logout() {
      window.location.replace('https://login.research.latis.umn.edu/logout?response_type=token&client_id=2f8e8pntgoiq5dn0gdihepm5m3&logout_uri=https://comn.psych.umn.edu/');
    },
	async new_get_tasks (authorization) {
      // sets the start_time of the run
      // set below via   .post(`${process.env.VUE_APP_ROOT_API}/notifications/`, {
      
      // console.log(authorization)
      
      let VUE_APP_ROOT_API = "https://apigateway.research.latis.umn.edu"
      let ENV = 'dev'
      let ENDPOINT  = 'comn/tasks'
      let headers = {'Authorization': authorization}
      let data = [];
      const response = await fetch(`${VUE_APP_ROOT_API}/${ENV}/${ENDPOINT}`, {
        "Accept": "application/json",
        "Content-Type": "application/json",
        method: "GET",
        cache: "no-cache",
        headers: headers
      }).then(response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }
      }).catch(function (e) { console.error(e.toString());}) // Sentry.captureException(e) })
      

      
      var task_json = await response.json()
      // return response;
      


      //let tasks = await response.json();
      
      /*
      .then(response => {
        let tasks =  response.result.tasks;
        for(let i = 0; i < tasks.length; i++) {
          let obj = tasks[i];
          let task = {"id": obj.id , "task_seq": obj.task_seq, "distribution_link": obj.disrtibution_link, "descr": obj.descr, "task_status": obj.task_status}
          console.log(`task: ${task.id}`)
          data = [task]
          console.log(`adding: ${data}`)
          
        }  }
      )
      */
      /*
      let tasks =  data.result.tasks;
      for(let i = 0; i < tasks.length; i++) {
        let obj = tasks[i];
        let task = {"id": obj.id , "task_seq": obj.task_seq, "distribution_link": obj.disrtibution_link, "descr": obj.descr, "task_status": obj.task_status}
        console.log(`task: ${task.id}`)
        data = [task]
        console.log(`adding: ${data}`)
        
      }
      */

      /*
      .then(function(data) {
        let tasks=  data.result.tasks;
        console.log(tasks)
        data = tasks
        for(let i = 0; i < tasks.length; i++) {
          let obj = tasks[i];
          let task = {"id": obj.id , "task_seq": obj.task_seq, "distribution_link": obj.disrtibution_link, "descr": obj.descr, "task_status": obj.task_status}
          console.log(`task: ${task.id}`)
          data = [task]
          console.log(`adding: ${data}`)
          
        }
      })
*/
      
      // console.log(`returning: ${data}`)
/*      
       data = [
        {
            "id": "b5c3617a-8f0f-4b5c-8d6c-f783e895acee",
            "task_seq": 1,
            "distribution_link": "https://umn.qualtrics.com/jfe/form/SV_1SNrkIDI31QncW1?Q_CHL=gl&Q_DL=Nj1OQLLo0YddpkV_1SNrkIDI31QncW1_CGC_LG3SZAb26KVhMId&_g_=g&PID=0NKVruYE&Pass=23ufcRUbK9grEu&task_id=b5c3617a-8f0f-4b5c-8d6c-f783e895acee",
            "descr": "Parent Self-Report Survey",
            "task_status": "COMPLETED"
        }
      ]
 */       
    },
    get_tasks({participantId}){


      let VUE_APP_ROOT_API = "https://apigateway.research.latis.umn.edu"
      let ENV = 'dev'
      let ENDPOINT  = 'comn/tasks'
      fetch(`${VUE_APP_ROOT_API}/${ENV}/${ENDPOINT}`, {
        "Accept": "application/json",
        "Content-Type": "application/json",
        method: "POST",
        cache: "no-cache",
      }).then(response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }
        console.log(response)
      }).catch(function (e) { console.error(e.toString()); Sentry.captureException(e) })



      let tasks = [
        {"id": "8115f02d-30dc-5eb1-94b6-255e37c69d1b", "descr": "Parent Self Report", "link": "https://umn.qualtrics.com/SV_1234567890?{distribution_id}_{survey_id}_{contact_id}", "status": "Completed"},
        {"id": "8115f02d-30dc-5eb1-94b6-255e37c69d1b", "descr": "Child 1", "link": "https://umn.qualtrics.com/SV_1234567890?{distribution_id}_{survey_id}_{contact_id}", "status": "Completed"},
        {"id": "c0d73373-79a4-5ace-affe-c14a45de790d", "descr": "Child 2", "link": "https://umn.qualtrics.com/SV_1234567890?{distribution_id}_{survey_id}_{contact_id}", "status": "Started"},
        {"id": "2ea4ab87-4baf-501b-bdc7-157a59f355f2", "descr": "Child 3", "link": "https://umn.qualtrics.com/SV_1234567890?{distribution_id}_{survey_id}_{contact_id}", "status": "Not Started"},
      ]	
      
      /*
      const obj1 = JSON.parse('{"name":"John", "age":30, "city":"New York"}');
      */
      let data = [
        {
            "id": "b5c3617a-8f0f-4b5c-8d6c-f783e895acee",
            "task_seq": 1,
            "distribution_link": "https://umn.qualtrics.com/jfe/form/SV_1SNrkIDI31QncW1?Q_CHL=gl&Q_DL=Nj1OQLLo0YddpkV_1SNrkIDI31QncW1_CGC_LG3SZAb26KVhMId&_g_=g&PID=0NKVruYE&Pass=23ufcRUbK9grEu&task_id=b5c3617a-8f0f-4b5c-8d6c-f783e895acee",
            "descr": "Parent Self-Report Survey",
            "task_status": "COMPLETED"
        },
        {
            "id": "85f195f7-7db1-4e3d-910a-2c09decb2db2",
            "task_seq": 2,
            "distribution_link": "https://umn.qualtrics.com/jfe/form/SV_1SNrkIDI31QncW1?Q_CHL=gl&Q_DL=xQtEysKJUufkEzr_1SNrkIDI31QncW1_CGC_jOT410BGU5ItK95&_g_=g&PID=0NKVruYE_01OKM9DL&Pass=OMH446RYl7tL&task_id=85f195f7-7db1-4e3d-910a-2c09decb2db2",
            "descr": "About Your Child - Child 1",
            "task_status": "STARTED"
        },
        {
            "id": "12da7996-4840-47f2-956e-2471d0d8b410",
            "task_seq": 3,
            "distribution_link": "https://umn.qualtrics.com/jfe/form/SV_1SNrkIDI31QncW1?Q_CHL=gl&Q_DL=iamCWSHGzvYixBi_1SNrkIDI31QncW1_CGC_y7UiNw6Hrpbwu7F&_g_=g&PID=0NKVruYE_02VRZ5VO&Pass=G6sZUCcMcnX4&task_id=12da7996-4840-47f2-956e-2471d0d8b410",
            "descr": "About Your Child - Child 2",
            "task_status": "NOT_STARTED"
        },
        {
            "id": "85a2322b-0208-4298-ade5-fec7ef9ccb6e",
            "task_seq": 4,
            "distribution_link": "https://umn.qualtrics.com/jfe/form/SV_1SNrkIDI31QncW1?Q_CHL=gl&Q_DL=hu9YRlYRCHoIgYg_1SNrkIDI31QncW1_CGC_yiVyP1HwXfyxnvx&_g_=g&PID=0NKVruYE_030RU8RW&Pass=GxpNtvkX3zug&task_id=85a2322b-0208-4298-ade5-fec7ef9ccb6e",
            "descr": "About Your Child - Child 3",
            "task_status": "NOT_STARTED"
        },
        {
            "id": "861d11b6-d065-4fee-b8a9-d11ad109ba72",
            "task_seq": 5,
            "distribution_link": "https://umn.qualtrics.com/jfe/form/SV_1SNrkIDI31QncW1?Q_CHL=gl&Q_DL=GPAdannKmexjomr_1SNrkIDI31QncW1_CGC_CZIz3fvLdb8AtC5&_g_=g&PID=0NKVruYE_04HCGEPZ&Pass=UVfkxD6uwjTn&task_id=861d11b6-d065-4fee-b8a9-d11ad109ba72",
            "descr": "About Your Child - Child 4",
            "task_status": "NOT_STARTED"
        },
        {
            "id": "c59977e6-78f5-46c4-96e5-5981b3905a9d",
            "task_seq": 6,
            "distribution_link": "https://umn.qualtrics.com/jfe/form/SV_1SNrkIDI31QncW1?Q_CHL=gl&Q_DL=S7CuoLULIaAd5hw_1SNrkIDI31QncW1_CGC_Rq2mopy3YwbbBqY&_g_=g&PID=0NKVruYE_05WAL4IK&Pass=FCNlQYUcEc96&task_id=c59977e6-78f5-46c4-96e5-5981b3905a9d",
            "descr": "About Your Child - Child 5",
            "task_status": "NOT_STARTED"
        },
        {
            "id": "e5cd2215-d370-4980-9b08-84cc31b5355c",
            "task_seq": 7,
            "distribution_link": "https://umn.qualtrics.com/jfe/form/SV_1SNrkIDI31QncW1?Q_CHL=gl&Q_DL=e5O8OOFsQ4F3qRJ_1SNrkIDI31QncW1_CGC_cEQPCG5RpYmFKRF&_g_=g&PID=0NKVruYE_06DCH4EO&Pass=NNQAiS34ufGV&task_id=e5cd2215-d370-4980-9b08-84cc31b5355c",
            "descr": "About Your Child - Child 6",
            "task_status": "NOT_STARTED"
        }
    ];
    //var jsonData = JSON.parse(data);




      return data
    }
    }
  }
export default taskModule
