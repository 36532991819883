<template>
  <div class="container">
      <p class="instructions">
        Welcome to the COMN Paths Survey Portal! Please login using the details provided in your email. 
        You will then be able to see your available surveys and track your progress.
      </p>
      <p class="instructions">
        If you have any questions, please refer to the contact information in your email.
      </p>
      <p class="instructions">
        Thank you again for participating!
    </p>
  </div>
</template>

<script>
// @ is an alias to /src
// import TasksBody from '@/components/TasksBody.vue'
// import LoginLogout from '@/components/body_components/LoginLogout.vue'

export default {
  name: 'HomeView',
  mounted() {
    // var currentUrl = window.location.pathname;
    // var currentSearch = window.location.query;
    // console.log(currentSearch)

    // const code = urlParams.get('code')
    /*
    var params = []
    var code = undefined
    var state = undefined
    params = window.location.search.substr(1).split("&")
    params.forEach(function (item) {
      let tmp = item.split("=");
      if (tmp[0] === "code") {
        code = tmp[1]
      }else if(tmp[0] === 'state') {
        state = tmp[1]
      }
    });

console.log(code)
console.log(state)
*/

  },
  components: {
    /// TasksBody,
    // LoginLogout
  }
}
</script>
<style>
.instructions {
  font-weight: 600;
  margin-right: 33%;
  margin-left: 33%;
}
</style>
