<template>
    <div class="hello">
      
  
      <button v-if="!loggedIn" @click="login">Sign in</button>
      <button v-else @click="logout">Sign out</button>
  
     </div>
  </template>
  
  <script>
  import { useStore } from 'vuex'

  export default {
    name: 'LoginLogout',
    data: () => ({
      loggedIn: false
    }),
    mounted(){
        let store = useStore()
        this.loggedIn = store.getters.loggedIn;
        if(this.loggedIn){
            this.login()
        }else{
            this.logout()
        }
        
    },
    methods:{
      login(){

        window.location.replace('https://cla-psych-comn-dev.auth.us-east-2.amazoncognito.com/login?response_type=code&client_id=2dg6adm7dkvgsa73qviu49iocj&redirect_uri=http://localhost:3000/login-redirect&state=STATE&scope=openid+email+aws.cognito.signin.user.admin');
        //const data = await response.json();
  
        //$router. push('www.yoursite.com/blog')
        // &username=wozniak&password=vP20rrptxd5FuLIZZbhw
        // alert(data.total);
  
      },
      logout(){
        console.log("trying to logout")
        window.location.replace('https://cla-psych-comn-dev.auth.us-east-2.amazoncognito.com/logout?response_type=code&client_id=2dg6adm7dkvgsa73qviu49iocj&logout_uri=http://localhost:3000/logout');
        //const data = await response.json();
  
        //$router. push('www.yoursite.com/blog')
        // &username=wozniak&password=vP20rrptxd5FuLIZZbhw
        // alert(data.total);
  
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>
  