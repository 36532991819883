import 'bootstrap/dist/css/bootstrap.css'
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'


const app = createApp(App).use(router);
app.use(store)
app.mount('#app')
import 'bootstrap/dist/js/bootstrap.js'
import router from './router'