<template>
    <div class="fixed">
        <p class="instructions">
            If you have any questions, please refer to the contact information in your email.
        </p>
        <p class="instructions">
            Thank you again for participating!
        </p>
    </div>
</template>
<style>
.instructions {
  font-weight: 600;
  margin-right: 15%;
  margin-left: 15%;
}
</style>
