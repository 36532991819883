<template>
<div class="card m-3">
    <h4 class="card-header">Tasks</h4>
        <div class="card-body">
            <div v-for="(task) in completed_tasks" v-bind:key="task.id"  class="list-group list-group-light">
                <a :href=task.distribution_link target="_blank" class="list-group-item list-group-item-secondary disabled list-group-item-action px-3 border-0" >
                    <h2>{{ task.descr }}</h2> <h5>Status: {{ task.task_status.replace(/_|\-/, " ") }}</h5>
                </a>
            </div>
            <div v-for="(task) in started_tasks" v-bind:key="task.id"  class="list-group list-group-light">
                <a :href=task.distribution_link target="_blank" class="list-group-item list-group-item-primary  list-group-item-action px-3 border-0" >
                    <h2>{{ task.descr }}</h2>  <h5>Status: {{ task.task_status.replace(/_|\-/, " ") }}</h5>
                </a>
            </div>
            <div v-for="(task) in not_started_tasks" v-bind:key="task.id"  class="list-group list-group-light">
                <a :href=task.distribution_link target="_blank" class="list-group-item list-group-item-info  list-group-item-action px-3 border-0" >
                    <h2>{{ task.descr }}</h2>  <h5>Status: {{ task.task_status.replace(/_|\-/, " ") }}</h5>
                </a>
            </div>
        </div>
</div>
</template>

<script>
/* eslint-disable */
import { useStore } from 'vuex'

export default {
    async setup() {
        const store = useStore()
        if(store.getters.loggedIn){
            console.log("getting tasks")
            var authorization = store.getters.getAuthorization
            //console.log(`with auth coode: ${authorization}`)
            let VUE_APP_ROOT_API = "https://apigateway.research.latis.umn.edu"
            let ENV = 'prod'
            let ENDPOINT  = 'comn/tasks'
            let headers = {'Authorization': authorization}
            var completed_tasks = []
            var started_tasks = []
            var not_started_tasks = []

            const response = await fetch(`${VUE_APP_ROOT_API}/${ENV}/${ENDPOINT}`, {
                "Accept": "application/json",
                "Content-Type": "application/json",
                method: "GET",
                cache: "no-cache",
                headers: headers
            })        
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status);
                return;
            }else{
                const json = await response.json()
                //console.log("back")
                console.log(`tasks: ${JSON.stringify(json.result.tasks)}`)
                const tasks = json.result.tasks      
                for(let i = 0; i < tasks.length; i++) {
                    let obj = tasks[i];
                    //console.log(`obj: ${obj}`)
                    let task = {"id": obj.id , "task_seq": obj.task_seq, "distribution_link": obj.distribution_link, "descr": obj.descr, "task_status": obj.task_status}
                    //console.log(`task: ${task.task_status}`)
                    if(task.task_status === 'COMPLETED' ){
                        console.log(task.distribution_link)
                        completed_tasks.push(task)
                    }else if(task.task_status === 'IN_PROGRESS'){
                        started_tasks.push(task)
                    }else{
                        not_started_tasks.push(task)
                    }
                }  
            }
            return {completed_tasks, started_tasks, not_started_tasks}
        }else{
            return {completed_tasks : [], started_tasks: [], not_started_tasks: []}
        }
    }   
}

</script>   
