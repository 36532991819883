<template>
    <div class="tasks">
        <div class="h-100 d-flex align-items-center flex-column justify-content-center">
          <Suspense>
            <TasksList/>
            <template #fallback>
              <strong>Loading...</strong>
            </template>
          </Suspense>
        </div>
        <TasksFooter/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import TasksList from '@/components/body_components/TaskList.vue'
  import TasksFooter from '@/components/TasksFooter.vue'
  
  export default {
    name: 'TasksView',
    components: {
      TasksList,
      TasksFooter
    }
  }
  </script>
  
