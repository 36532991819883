 /* eslint-disable */
import Vuex from 'vuex'
import taskModule from './api/taskModule'
import project from '../package.json'

const state = { 
    version: project.version,
    participantId: undefined,
    passCode: undefined,
    tasks: undefined,
    loggedIn: false,
    authorization: undefined,
    expires_in: undefined
}
const modules = {
    taskModule
}
export const getters = {
    getAuthorization(state){
        return state.authorization
    },
    getTasks(state){
        // check localstorage for tasks
        // return tasks if found
        // if( localStorage.getItem("tasks")) {
            // return localStorage.getItem("tasks")
        // }
        // console.log(`numtasks: ${state.tasks[0]}`)
        return state.tasks
    },
    loggedIn(state){
        // check localstorage for participantid and passcode
        // return true if found
        //if( localStorage.getItem("participantId")) {
         if(state.loggedIn){
            // console.log(`pid: ${localStorage.getItem("participantId")}`)
            //console.log(state.loggedIn);
            return true
        }else{
            false
        }
        // return state.loggedIn
    }
}
export const mutations = {
    setUser(state, payload){
        var authCode = payload[0]
        var authState = payload[1]
        var autorization = payload[2]
        var expiresIn = payload[3]
        state.loggedIn = true
        state.participantId = authCode
        state.authorization = autorization
        state.expiresIn = expiresIn
        localStorage.setItem("participantId", authCode);
        localStorage.setItem("authState", authState);
        localStorage.setItem("autorization", autorization);
        localStorage.setItem("expiresIn", expiresIn);
        localStorage.setItem("timeStamp", Date.now())
    },
    unsetUser(state){
        state.participantId = undefined
        state.tasks = undefined
        state.loggedIn = false
        state.authorization = undefined
        state.expiresIn = undefined
        localStorage.clear();
    },
    login (state){
        taskModule.actions.oauth_login();    
    },
    logout(state){
        state.loggedIn = false
        taskModule.actions.oauth_logout();
    }
}
export const actions = {
    login(context) {
        context.commit('login')
    },
    logout(context) {
        context.commit('logout')
    }
}    
export default new Vuex.Store({
    state,
    getters,
    modules,
    mutations,
    actions
})