import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import TasksView from '../views/TasksView.vue'
import LoginLogout from '../components/body_components/LoginLogout.vue'
import AuthBounce from '../views/AuthBounce.vue'
import { useStore } from 'vuex'

function ensureAuthenticated() { // to) {
  // if (to.hash) return { path: to.path, query: to.query, hash: '' }
  const store = useStore()

  if(store.getters.loggedIn){
    console.log(`in eAuth: ${store.getters.loggedIn}`)
    console.log("looggged in")
  }else{
    store.commit("login");    
  }
  
  // this.$store.dispatch("login");
}

const routes = [
  {
    path: '/login-redirect/:pathMatch(.*)*',
    name: 'login-redirect',
    component: TasksView,
    params: true
  },
  {
    path: '/logout_redirect',
    name: 'logout_redirect',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginLogout
  },
  {
    path: '/logout-old',
    name: 'logout-old',
    component: HomeView
  },
  {
    path: '/logout_test',
    name: 'logout_test',
    component: LoginLogout
  },
  {
    path: '/surveys',
    name: 'surveys',
    component: TasksView,
    beforeEnter: [ensureAuthenticated],
  },
  {
    path: '/authbounce',
    name: 'AuthBounce',
    component: AuthBounce,
    props: true
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
export default router
