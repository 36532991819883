
<script>

export default {
  props: {
    inout: Object
  },
  setup(props) {
    // setup() receives props as the first argument.
    console.log(props.inout)
    console.log("aefdzcs")
  }
}


</script>